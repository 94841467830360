const getDataHora = () => {
  const options = {
    timeZone: "America/Sao_Paulo",
    hour: "numeric",
    minute: "numeric",
  };
  const date = new Intl.DateTimeFormat([], options);
  const now = new Date();
  return (
    now.getUTCDate() +
    "/" +
    (now.getMonth() + 1) +
    "/" +
    now.getFullYear() +
    " " +
    date.format(now)
  );
};

export { getDataHora };
