import React, { useEffect, useState } from "react";
import { useCmsData } from '../../hooks/cms';
import "./home.css";
import { Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getSportsData, pushDataFirestone } from "../../services/ApiService";
import { getDataHora } from "../../utils";
import Analytics from "../../services/Analytics";
import InputMask from "react-input-mask";
import isEmail from "validator/lib/isEmail";
import NativeSelect from "@material-ui/core/NativeSelect";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Cell = ({ setSubmitted }) => {
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [esporte, setEsporte] = useState("");
  const [receberMsg, setReceberMsg] = useState(false);
  const [cmbEsportes, setcmbEsportes] = useState([]);
  const [ocasiao, setOcasiao] = useState('');

  const { dados: doc } = useCmsData();

  useEffect(() => {
    getSportsData()
      .then((response) => response.json())
      .then((data) => setcmbEsportes(data))
      .catch((error) => console.error);
  }, []);

  useEffect(() => {
    if(cmbEsportes.length > 0){
      setEsporte(cmbEsportes[0].id)
      setOcasiao('praticaEsportiva');
    }
  }, [cmbEsportes]);

  const validarCpf = require("validar-cpf");

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const handleSubmit = (e) => {
    Analytics.sendEvent("formulario_enviando", {
      estabelecimento: doc.estabelecimento,
      email: email,
      cpf: cpf,
      nome: nome,
      telefone: telefone,
      esporteFavorito: esporte,
      receberMsg: receberMsg,
      ocasiaoDeUso: ocasiao,
    });

    if (isEmail(email)) {
      if (validarCpf(cpf)) {
        pushDataFirestone({
          estabelecimento: doc.estabelecimento,
          email: email,
          cpf: cpf,
          nome: nome,
          telefone: telefone,
          esporteFavorito: esporte,
          receberMsg: receberMsg,
          ocasiaoDeUso: ocasiao,
          dataHora: getDataHora(),
        });
        setSubmitted(true);
      } else {
        alert("CPF inválido!");
        Analytics.sendEvent("cpf_invalido");
      }
    } else {
      alert("E-mail inválido!");
      Analytics.sendEvent("email_invalido");
    }
    e.preventDefault();
  };

  const handleClickNome = (e) => {
    Analytics.sendEvent("clique_nome");
    e.preventDefault();
  };

  const handleClickEmail = (e) => {
    Analytics.sendEvent("clique_email");
    e.preventDefault();
  };

  const handleClickCPF = (e) => {
    Analytics.sendEvent("clique_cpf");
    e.preventDefault();
  };

  const handleClickTelefone = (e) => {
    Analytics.sendEvent("clique_telefone");
    e.preventDefault();
  };

  const handleChangeEsporte = (e) => {
    setEsporte(e.target.value);
  };

  const handleChangeOcasiao = (e) => {
    setOcasiao(e.target.value);
  };

  const handleChangeReceberMsg = (e) => {
    setReceberMsg(e.target.checked);
  };

  return (
    <div className="cell">
      <label className="cell-label-info">
        Digite os seus dados para desbloquear o cupom de desconto
      </label>
      <Form className="form-div">
        <Form.Group controlId="formBasicNome">
          <Form.Label className="form-label-info">Nome</Form.Label>
          <Form.Control
            className="form-label-input"
            type="nome"
            placeholder="Digite o nome"
            onFocus={(e) => handleClickNome(e)}
            onChange={(e) => setNome(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="form-label-info">Email</Form.Label>
          <Form.Control
            className="form-label-input"
            type="email"
            placeholder="Digite o email"
            onFocus={(e) => handleClickEmail(e)}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicCpf">
          <Form.Label className="form-label-info">CPF</Form.Label>
          <InputMask
            className="form-label-input form-cpf"
            type="cpf"
            placeholder="Digite o CPF"
            mask="999.999.999-99"
            onFocus={(e) => handleClickCPF(e)}
            onChange={(e) => setCpf(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicTelefone">
          <Form.Label className="form-label-info">Telefone</Form.Label>
          <InputMask
            className="form-label-input form-cpf"
            type="cpf"
            placeholder="Digite o Telefone"
            mask="(99) 99999-9999"
            onFocus={(e) => handleClickTelefone(e)}
            onChange={(e) => setTelefone(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEsporte">
          <Form.Label className="form-label-info">Ocasiões de uso</Form.Label>
          <NativeSelect
            id="demo-customized-select-native"
            value={ocasiao}
            onChange={handleChangeOcasiao}
            input={<BootstrapInput />}
          >
              <option value='praticaEsportiva' key={0}>Prática esportiva</option>
              <option value='usoCasual' key={1}>Uso casual</option>
              <option value='presentearAlguem' key={2}>Presentear alguém</option>

          </NativeSelect>
        </Form.Group>

        <Form.Group controlId="formBasicEsporte">
          <Form.Label className="form-label-info">Esporte favorito</Form.Label>
          <NativeSelect
            id="demo-customized-select-native"
            value={esporte}
            onChange={handleChangeEsporte}
            input={<BootstrapInput />}
          >
            {cmbEsportes.map((row) => (
              <option value={row.id} key={row.id}>
                {row.nome}
              </option>
            ))}
          </NativeSelect>
        </Form.Group>

        <Form.Group controlId="formBasicAceito">
          <FormControlLabel
            control={
              <Checkbox
                checked={receberMsg}
                onChange={handleChangeReceberMsg}
                name="receberMensagem"
              />
            }
            label="Eu aceito receber mensagens da Centauro"
          />
        </Form.Group>

        { doc && 
        (
          <div className="cell-label-container">
            <label className="cell-label-info-footer"> 
              {doc.condicaoum}
            </label>

            <label className="cell-label-info-footer">
              {doc.condicaodois}
            </label>
          </div>
        )}

        <Button
          className="btn-acessar-cupom"
          variant="primary"
          type="submit"
          onClick={(e) => handleSubmit(e)}
        >
          Acessar cupom
        </Button>
      </Form>
    </div>
  );
};

export default Cell;
