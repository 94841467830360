import React, { useEffect, useState } from "react";
import { useCmsData } from '../../hooks/cms';
import imgPista from "../../assets/images/pista.png";
import imgLogo from "../../assets/images/logobranco.png";
import Cell from "./Cell";
import Cupom from "./Cupom";
import "./home.css";
import "typeface-roboto";
import Analytics from "../../services/Analytics";

const Home = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const { dados: doc } = useCmsData();
  useEffect(() => {
    Analytics.sendEvent("app_carregado");
  }, []);

  return (
  <div className="App">
    <div className="container-div-up">
      <div className="container-home">
        <div className="header-home">
          <img src={imgPista} alt="Pista" />
          <img className="image-logo" src={imgLogo} alt="Pista" />
        </div>
        <div className="body-home">
          <label className="label-home-msg">Você Ganhou</label>
          <br></br>
          { doc && <label className="label-home-desconto">{doc.desconto}</label> }
        </div>
      </div>
    </div>
    <div className="container-div-down">
      {isSubmitted ? <Cupom /> : <Cell setSubmitted={setSubmitted} />}
    </div>
  </div>
  );
};

export default Home;
