import React from 'react';
import imgPista from "../../assets/images/pista.png";
import imgLogo from "../../assets/images/logobranco.png";
import './style.css';
const NotFound = () => {
  return (
      <div class="container">
        <img src={imgPista} alt="Pista" />
        <img className="image-logo" src={imgLogo} alt="Pista" />
        <div class="info">
          <h1>Ops cupom não encontrado!</h1>
        </div>
      </div>
  )
}

export default NotFound;