import React from "react";
import "./App.css";
import Routes from './routes';
import FirebaseService from "./services/FirebaseService";
import Analytics from "./services/Analytics";

FirebaseService.initialize();
Analytics.initialize();

function App() {
  return (
    <Routes />
  );
}

export default App;
