import firebase from "firebase";

export default class Analytics {
  static initialize = () => {
    firebase.analytics();
    console.log("=> Analytics", "Analytics");
  };
  static sendEvent = (evento, valor) => {
    firebase.analytics().logEvent(evento, valor);
    console.log("=> Analytics", "Evento " + evento);
  };
}
