import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Home from '../components/home/Home';
import NotFound from '../components/notfound';
import { CmsProvider } from '../hooks/cms';

const Routes = () => {

  return (
    <Router>
        <Switch>
        <Route path="/cupom-invalido" component={NotFound} />
        <Route exact path="/:slug"> 
          <CmsProvider>
            <Home />
          </CmsProvider>
        </Route>
      </Switch>
    </Router>
  );
}

export default Routes;