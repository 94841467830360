import React, { useEffect} from "react";
import "./home.css";
import { useCmsData } from '../../hooks/cms';
import "bootstrap/dist/css/bootstrap.min.css";
import Analytics from "../../services/Analytics";

const Cupom = () => {
  const { dados: doc } = useCmsData();

  useEffect(() => {
    if(doc) {
      Analytics.sendEvent("cupom_exibido", { cupom: doc.codigocupom });
    }    
  }, [doc]);
  
  return (
    <div className="cell-cupom">
      <label className="cell-label-info-cupom">Use o cupom</label>
      <div className="cupom-div">
        <label className="cell-label-info-cupom-nome">{doc && doc.codigocupom}</label>
      </div>
      <label className="cell-label-info-cupom-validade">
        {doc && doc.validade}
      </label>

      { doc && 
        (
          <div className="cell-label-container">
            <label className="cell-label-info1-cupom">
              {doc.condicaoum}  <br/>
              {doc.condicaodois}
            </label>
          </div>
        )}

      <label className="cell-label-info-cupom-obs">
        Os cupons não são cumulativos. Sendo assim, não é possível utilizar o
        mesmo cupom em mais de um pedido. O desconto do cupom é fornecido apenas
        uma vez por pessoa, atrelado ao número do documento do comprador. O
        cupom é válido somente para compras na loja física {doc && doc.estabelecimento}. O
        cupom não é limitado por quantidade de peças ou valor da compra. A
        validade do cupom está descrita junto a sua divulgação. O cupom de
        desconto é limitado, sendo assim, ele pode deixar de ser válido assim
        que a quantidade limite de uso do cupom for atendida. Em caso de
        cancelamento da compra, devido à troca ou arrependimento, o valor a ser
        restituído será o mesmo do pagamento efetuado, já incluído o desconto.
      </label>
    </div>
  );
};

export default Cupom;
