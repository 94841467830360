export const firebaseConfig = {
  apiKey: "AIzaSyBXhrSsoEE5mm6dcxMX8Ttbq-xu8KqJAe4",
  authDomain: "fir-cupominauguracao.firebaseapp.com",
  databaseURL: "https://fir-cupominauguracao.firebaseio.com",
  projectId: "firebasecupominauguracao",
  storageBucket: "firebasecupominauguracao.appspot.com",
  messagingSenderId: "854249462453",
  appId: "1:854249462453:web:a00fce0f9028fff038fcfc",
  measurementId: "G-WXC3S47DVQ",
};
